<template>
  <section id="message-media">
    <form class="wrapper" @submit.prevent="sendMessage">
      <KonaUpload
        :accept="acceptedMediaTypes"
        @onChange="updateFile"
        :placeholder="onlyImages ? lang.botMaker.addBot.widget.onlyImage[languageSelected] : undefined"
        ref="konaUpload"
        :read-only="readOnly"
      />
    </form>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { MEDIA_TYPES, IMAGES_TYPE } from '@/constants/constants'
import { MediaMessageType } from '@/models/Messages/MessageTypes/MediaMessageType'

export default {
  components: {
    KonaUpload: () => import('@/components/KonaUpload.vue')
  },
  props: {
    hasErrors: Boolean,
    emitType: {
      Type: Boolean,
      default: false,
      required: false
    },
    onlyImages: {
      type: Boolean,
      default: false,
      required: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mediaType: 'image',
      input: '',
      search: '',
      inputDisabled: false,
      buttonDisabled: false,
      showPreviewVideo: false,
      MEDIA_TYPES
    }
  },
  watch: {
    '$store.state.botMaker.botMessageInEdit.id'() {
      this.resetUpload()
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['botMessageInEdit']),
    acceptedMediaTypes() {
      if(this.onlyImages) {
        return IMAGES_TYPE.join(',');
      }
      return MEDIA_TYPES.join(',')
    },
  },
  methods: {
    resetUpload() {
      // Verify if component exists, if not try again later
      if (this.$refs.konaUpload) this.$refs.konaUpload.reset()
      else {
        setTimeout(() => {
          this.resetUpload()
        }, 50)
      }
    },
    updateFile(file) {
      if (this.emitType) {
        const botDialogHandler = JSON.parse(
          JSON.stringify(this.botMessageInEdit)
        )
        const botDialog = botDialogHandler.type

        const mediaMessage = new MediaMessageType(
          (botDialog.responseFile
            ? botDialog.responseFile.name
            : botDialog.name) || botDialog.responseText,
          '',
          botDialog.responseFile ? botDialog.responseFile.url : null,
          botDialog.responseFile ? botDialog.responseFile.type : null,
          botDialog.platforms,
          botDialog._id,
          botDialog.responseId
        )
        // Then, seteamos el archivo en el nuevo botMessageInEdit
        mediaMessage.setMessage(file)
        this.$emit('uploadedFile', mediaMessage)
      } else {
        console.log('FILE::', file)
        this.$emit('uploadedFile', true)
        this.botMessageInEdit.type.fileUrl = null

        console.log('BEFORE UPLOAD::', this.botMessageInEdit)

        // Si originalmente no era una imagen
        if (!this.botMessageInEdit.type.hasOwnProperty('setMessage')) {
          // Defino el botMessageInEdit como una imagen

          const botDialogHandler = JSON.parse(
            JSON.stringify(this.botMessageInEdit)
          )
          const botDialog = botDialogHandler.type

          this.botMessageInEdit.type = new MediaMessageType(
            (botDialog.responseFile
              ? botDialog.responseFile.name
              : botDialog.name) || botDialog.responseText,
            '',
            botDialog.responseFile ? botDialog.responseFile.url : null,
            botDialog.responseFile ? botDialog.responseFile.type : null,
            botDialog.platforms,
            botDialog._id,
            botDialog.responseId
          )
          // Then, seteamos el archivo en el nuevo botMessageInEdit
          this.botMessageInEdit.type.setMessage(file)

          console.log('this.botMessageInEdit: ', this.botMessageInEdit)
        } else {
          this.botMessageInEdit.type.setMessage(file)
        }
      }
    }
  }
}
</script>

<style lang="scss">
#message-media {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .wrapper {
    position: relative;
    width: 100%;
    display: flex !important;
    flex-direction: column;
    transition: 0.5s;

    .input {
      position: relative;
      .input-message {
        .vs-input--input.normal {
          padding-left: 40px;
        }
      }
    }

    .media-file {
      .preview {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        #preview-image {
          max-height: 150px;
        }

        #preview-video {
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
}

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 20rem;
  height: 12rem;
}

.emoji-invoker {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  margin: 7px 15px 7px 7px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  z-index: 10;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.user-says.emoji-picker {
  position: relative;
  font-family: Montserrat, sans-serif;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: '';
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

.input-message {
  width: 100%;
}

.user-says.input-message textarea,
.user-says.input-message .input-span-placeholder {
  padding-left: 40px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon-large .feather {
  height: 40px !important;
  width: 40px !important;
}

.icon-large .feather:hover {
  transform: scale(1.1);
}

.bot-maker .button {
  margin: 0; //8px 0px 8px 8px;
  position: unset !important;
  width: 20px !important;
  left: 5px !important;
  top: 0 !important;
  color: #b1c6d0;
  z-index: 10;
}
</style>
